import { ReactNode, createContext, useState } from "react";
import { MyTherapist } from "../models/TherapistModel";
import { User } from "../api/me";


type AppStoreContextProps = {
    kyraChatDatabase?: {
        value: IDBDatabase | undefined;
        set: React.Dispatch<React.SetStateAction<IDBDatabase | undefined>>;
    },
    myTherapist?: {
        value: MyTherapist | undefined;
        set: React.Dispatch<React.SetStateAction<MyTherapist | undefined>>;
    },
    myProfile?: {
        value: User | undefined;
        set: React.Dispatch<React.SetStateAction<User | undefined>>;
    }
}

export const AppStoreContext = createContext<AppStoreContextProps>({});

export const AppStoreProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    // TODO: Maybe we can use useRef here?
    const [chatDatabase, setChatDatabase] = useState<IDBDatabase | undefined>(undefined);
    const [myTherapist, setMyTherapist] = useState<MyTherapist | undefined>(undefined);
    const [myProfile, setMyProfile] = useState<User | undefined>(undefined);

    const obj = {
        chatDatabase: {
            value: chatDatabase,
            set: setChatDatabase
        },
        myTherapist: {
            value: myTherapist,
            set: setMyTherapist
        },
        myProfile: {
            value: myProfile,
            set: setMyProfile
        }
    };

    return (
        <AppStoreContext.Provider value={obj}>
            {children}
        </AppStoreContext.Provider>
    );
}
