import { useContext, useEffect, useMemo, useState } from "react";
import { NavigationWithProps } from "../../core/Types";
import { Bubble, Composer, GiftedChat, IMessage, InputToolbar, InputToolbarProps, Send } from "react-native-gifted-chat";
import { IconButton, MD3Theme, Text, useTheme } from "react-native-paper";
import { View, Image } from "react-native";
import { uuidv4 } from "../../utils/uuid";
import { ChatMessageUserType } from "../../core/api/chatbot";
import { RootNavParamList } from "../../../App";
import { usePublicApi } from "../../core/api/publicApi";
import { AppStoreContext } from "../../core/provider/AppStoreProvider";
import { getProfileImageUrl } from "../../utils/Procedures";
import { ChatMessage, useChat } from "../../core/api/chat";
import { TherapistChatContext } from "../../core/provider/TherapistChatProvider";

function bubbleRenderer(theme: MD3Theme) {
    return (props: Bubble<IMessage>['props']) => (
        <Bubble
            {...props}
            wrapperStyle={{
                right: {
                    backgroundColor: theme.colors.primary
                },
                left: {
                    backgroundColor: theme.colors.surface
                }
            }}
        />
    );
}

function inputToolbarRenderer(theme: MD3Theme, disableComposer: boolean = false) {
    return (props: InputToolbarProps<IMessage>) => (
        <InputToolbar
            {...props}
            containerStyle={{
                margin: 5,
                backgroundColor: theme.colors.surface,
                borderTopColor: theme.colors.surfaceVariant,
                borderRadius: 20,
                borderTopWidth: 1
            }}
            renderComposer={(props) => <Composer {...props} disableComposer={disableComposer} placeholder="Escribe algo..." textInputProps={{
                maxLength: 2000
            }} />}
            renderSend={(props) => <Send {...props} label="Enviar" textStyle={{
                color: theme.colors.primary
            }} />}
        />
    );
}

function useForceUpdate() {
    const [, setToggle] = useState(false)
    return () => setToggle(toggle => !toggle)
}

function isCloseToTop({ layoutMeasurement, contentOffset, contentSize }: any) {
    const paddingToTop = 20;
    return contentSize.height - layoutMeasurement.height - paddingToTop <= contentOffset.y;
}

const ChatTherapist: React.FC<NavigationWithProps<{}, RootNavParamList, "ChatTherapist">> = ({ navigation, route }) => {
    const theme = useTheme();
    const forceUpdate = useForceUpdate();

    const appContext = useContext(AppStoreContext);
    const chatContext = useContext(TherapistChatContext);
    const publicApi = usePublicApi();
    const chat = useChat();

    const therapist = useMemo(() => {
        return appContext.myTherapist?.value?.therapist!;
    }, [appContext.myTherapist]);

    const [messages, setMessages] = useState<IMessage[]>([]);
    const [historyPage, setHistoryPage] = useState(0);
    const [loading, setLoading] = useState(false);

    const onSend = async (nMessages: IMessage[] = []) => {
        chat.sendMessage(nMessages[0].text)
            .then(() => {
                setMessages(previousMessages => {
                    previousMessages.unshift(nMessages[0]);
                    return previousMessages;
                });
                forceUpdate();
            });
    };

    const loadHistory = (overridePage?: number) => {
        if (loading) {
            return;
        }

        setLoading(true);
        chat.listHistory(overridePage ?? historyPage)
            .then((res) => {
                setMessages(previousMessages => {
                    previousMessages.push(...res.items.map((x) => ({
                        _id: uuidv4(),
                        text: x.message,
                        createdAt: x.send_at,
                        user: {
                            _id: x.from_therapist ? ChatMessageUserType.THERAPIST : ChatMessageUserType.USER,
                            name: x.from_therapist ? therapist?.full_name : "Patient",
                            avatar: () => x.from_therapist ? <Image
                                style={{ width: 40, height: 40, borderRadius: 50 }}
                                source={getProfileImageUrl(therapist, publicApi)} /> : <></>
                        },
                    })));
                    return previousMessages;
                });
            })
            .catch(() => {
            })
            .finally(() => {
                setLoading(false);
            });
    };


    const onMessageReceived = (message: Event) => {
        const detail = (message as CustomEvent<ChatMessage>).detail;
        setMessages(previousMessages => {
            previousMessages.unshift({
                _id: uuidv4(),
                text: detail.message,
                createdAt: detail.send_at,
                user: {
                    _id: detail.from_therapist ? ChatMessageUserType.THERAPIST : ChatMessageUserType.USER,
                    name: detail.from_therapist ? therapist?.full_name : "Patient",
                    avatar: () => detail.from_therapist ? <Image
                        style={{ width: 40, height: 40, borderRadius: 50 }}
                        source={getProfileImageUrl(therapist, publicApi)} /> : <></>
                },
            });
            return previousMessages;
        });

        // For some reason, the chat doesn't update without this...
        forceUpdate();
    }

    const goBack = () => {
        navigation.goBack();
    };

    useEffect(() => {
        setHistoryPage(1);
        loadHistory(1);
        chatContext.eventListener?.value.addEventListener("message", onMessageReceived);

        return () => {
            chatContext.eventListener?.value.removeEventListener("message", onMessageReceived);
        };
    }, []);

    return (<>
        <View style={{
            backgroundColor: theme.colors.background,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
        }}>

            <IconButton style={{
                marginLeft: 10,
            }}
                onPress={goBack}
                icon="arrow-left" />
            <Text style={{
                fontSize: 20,
                marginLeft: 10,
                color: theme.colors.onBackground
            }}>
                {therapist?.full_name}
            </Text>
        </View>
        <View style={{ flex: 1, backgroundColor: theme.colors.surfaceVariant }}>
            <GiftedChat
                renderBubble={bubbleRenderer(theme)}
                renderInputToolbar={inputToolbarRenderer(theme)}
                listViewProps={{
                    scrollEventThrottle: 400,
                    onScroll: ({ nativeEvent }: any) => {
                        if (isCloseToTop(nativeEvent)) {
                            setHistoryPage(historyPage + 1);
                            loadHistory(historyPage + 1);
                        }
                    }
                }}
                messages={messages}
                onSend={messages => onSend(messages)}
                user={{
                    _id: 2
                }}
            />
        </View>
    </>);
};

export default ChatTherapist;
