import React, { useEffect, useRef, useState } from 'react';
import { Animated, ImageBackground, View } from 'react-native';

import B from '../../../components/Bold';
import { Text, useTheme } from 'react-native-paper';
import GradientText from '../../../components/GradientText';
import { NavigationWithProps } from '../../../core/Types';
import { AppleAuthenticateRequest, useAuth } from '../../../core/api/auth';
import { CredentialResponse } from '@react-oauth/google';
import GoogleSignInButton from '../../../components/GoogleSigninButton';
import { LoginNavParamList } from '../Login';
import { useToast } from 'react-native-toast-notifications';
import AppleSignInButton from '../../../components/AppleSignInButton';
import { SignInResponseI } from '../../../../apple-sign-in';
import { getGoogleUser } from '../../../core/jwt/jwt';
import { ApiErrors } from '../../../core/models/ApiResponse';

type AuthProvider = "apple" | "google";

const AuthorizationStep: React.FC<NavigationWithProps<{}, LoginNavParamList, "Authorization">> = ({ navigation }) => {

    const opacityAnim = useRef(new Animated.Value(0)).current;

    const theme = useTheme();
    const auth = useAuth();
    const toast = useToast();

    const [loading, setLoading] = useState<AuthProvider>();

    const onSuccess = async (
        response: CredentialResponse | SignInResponseI,
        provider: AuthProvider
    ) => {
        let credential: string | AppleAuthenticateRequest;
        let loginPromise: Promise<void>;
        let userName: string | undefined;
        if (provider === "google") {
            credential = (response as CredentialResponse).credential!;
            userName = getGoogleUser(credential).name;
            loginPromise = auth.googleLogin(credential);
        } else if (provider === "apple") {
            response = response as SignInResponseI;
            credential = {
                ...response.authorization,
                full_name: response.user?.name
                    ? `${response.user?.name?.firstName} ${response.user?.name?.lastName}`
                    : "",
            };
            userName = response.user?.name?.firstName;
            loginPromise = auth.appleLogin(credential);
        } else {
            console.error("Invalid provider");
            return;
        }

        setLoading(provider);
        await loginPromise
            .then(() => {
                location.replace("/");
            })
            .catch(async (error: any) => {
                if (error.error == ApiErrors.ACCEPT_TERMS_ERROR) {
                    navigation.replace("TermsAndConditions", { userName, credential, provider });
                }
            })
            .catch(onError)
            .finally(() => {
                setLoading(undefined)
            });
    };

    const onError = () => {
        console.log('Error:', 'Error');
        toast.show("Error al iniciar sesión", { duration: 1000 });
    }

    useEffect(() => {
        Animated.timing(opacityAnim, {
            toValue: 1,
            duration: 1000,
            useNativeDriver: false
        }).start();
    }, []);

    return (
        <Animated.View style={{ height: "100%", opacity: opacityAnim }}>
            <ImageBackground
                style={{
                    height: "100%",
                    flexDirection: 'column',
                }}
                source={require("../../../../assets/img/scenes/fondo_login.png")}>
                <Text style={{
                    fontSize: 30,
                    textAlign: 'center',
                    marginTop: "35%",
                    marginHorizontal: 20,
                }}>
                    Hola, soy <GradientText style={{
                        fontSize: 30,
                        fontWeight: 'bold',
                    }} color1={'#3e7200'} color2={'#112D00'}><B>Kyra</B></GradientText>,
                    me alegra que estés aquí
                </Text>
                <View style={{
                    marginTop: "auto",
                    marginBottom: 90
                }}>
                    <Text style={{
                        fontSize: 20,
                        textAlign: 'center',
                        marginBottom: 10,
                        color: theme.colors.onTertiaryContainer,
                    }}>
                        <B>Inicia sesión para continuar</B>
                    </Text>
                    <View style={{
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginBottom: 10
                    }}>
                        <GoogleSignInButton
                            onSuccess={c => onSuccess(c, "google")}
                            onError={onError}
                            loading={loading === "google"}
                        />
                    </View>
                    <View style={{
                        marginLeft: "auto",
                        marginRight: "auto",
                    }}>
                        <AppleSignInButton
                            onSuccess={c => onSuccess(c, "apple")}
                            onError={onError}
                            loading={loading === "apple"}
                        />
                    </View>
                </View>
            </ImageBackground>
        </Animated.View >
    );
}

export default AuthorizationStep;
