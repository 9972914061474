import React, { useContext, useEffect, useMemo, useState } from 'react';
import { LinkingOptions, NavigationContainer, useNavigation } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';

import Login from './src/app/login/Login';
import Pages from './src/app/pages/Pages';
import { useTheme } from 'react-native-paper';
import { NavigationTheme } from 'react-native-paper/lib/typescript/types';
import { TherapistModel } from './src/core/models/TherapistModel';
import Therapist from './src/app/Therapist/Therapist';
import { SessionContext } from './src/core/provider/SessionProvider';
import { useTherapists } from './src/core/api/therapists';
import SelectTherapist from './src/app/Therapist/SelectTherapist';
import { AppStoreContext } from './src/core/provider/AppStoreProvider';
import { useMe } from './src/core/api/me';
import LoadingScreen from './src/components/LoadingScreen';
import ChatKyra from './src/app/Chat/ChatKyra';
import ChatTherapist from './src/app/Chat/ChatTherapist';
import { useChat } from './src/core/api/chat';

export type RootNavParamList = {
    "Login": undefined,
    "Onboarding": undefined,
    "Pages": undefined,
    "SelectTherapist": undefined,
    "Therapist": TherapistModel,
    "ChatKyra": undefined,
    "ChatTherapist": undefined,
};

const linking: LinkingOptions<RootNavParamList> = {
    enabled: true,
    prefixes: [
        /* your linking prefixes */
    ],
};

const Stack = createNativeStackNavigator<RootNavParamList>();

const App: React.FC = () => {

    const session = useContext(SessionContext);
    const appContext = useContext(AppStoreContext);

    const [ready, setReady] = useState(false);
    const [requestedTherapist, setRequestedTherapist] = useState<TherapistModel>();

    const hasRequestedTherapist = useMemo(() => {
        return requestedTherapist !== undefined;
    }, [requestedTherapist]);
    const hasSession = useMemo(() => {
        return session.session?.current !== undefined;
    }, [session]);
    const hasTherapist = useMemo(() => {
        return appContext.myTherapist?.value !== undefined;
    }, [appContext]);

    const theme = useTheme();
    const therapists = useTherapists();
    const me = useMe();
    const chat = useChat();

    const checkSession = () => {
        if (hasSession) {
            return true;
        }

        if (location.pathname !== "/") {
            location.href = "/";
        }

        return;
    }

    const checkTherapistInPath = async () => {
        if (location.pathname !== "/therapist") {
            return;
        }
        const params = new URLSearchParams(location.search);
        const therapistId = Number(params.get('id'));
        if (!therapistId || isNaN(therapistId)) {
            return;
        }

        const therapist = await therapists.getTherapist(therapistId)
            .catch(() => undefined);
        if (!therapist) {
            return;
        }
        setRequestedTherapist(therapist);
    };

    const init = async () => {
        if (!checkSession()) {
            setReady(true);
            return;
        }

        await Promise.all([
            checkTherapistInPath(),
            me.fetchMyProfile(),
            therapists.fetchMyTherapist(),
        ]);
        history.replaceState({}, '', '/');
        setReady(true);
    };

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        if (hasTherapist) {
            console.log('Connecting chat socket');
            chat.connectChatSocket();
        }
    }, [hasTherapist]);

    return (<>
        {ready ?
            <NavigationContainer
                documentTitle={{
                    //TODO: We should put this in a constants file
                    formatter: (options, route) =>
                        "Kyra Health",
                }}
                linking={linking}
                theme={theme as unknown as NavigationTheme}>
                <Stack.Navigator id='AppNavigator'
                    screenOptions={
                        { headerShown: false }
                    }
                    initialRouteName={hasSession ?
                        (hasRequestedTherapist ? 'Therapist' :
                            (hasTherapist ? 'Pages' : 'SelectTherapist'))
                        : 'Login'}>

                    {!hasSession && <Stack.Screen
                        name="Login"
                        component={Login}
                    />}

                    {hasSession && <Stack.Screen
                        name="Pages"
                        component={Pages}
                    />}

                    {hasSession && <Stack.Screen
                        name="SelectTherapist"
                        component={SelectTherapist}
                        listeners={
                            {
                                focus: (e) => {
                                    if (hasTherapist) {
                                        //TODO: We have to check how to make this using navigation...
                                        location.replace("/");
                                    }
                                }
                            }
                        }
                    />}

                    {hasSession && <Stack.Screen
                        name="Therapist"
                        component={Therapist}
                        initialParams={requestedTherapist}
                    />}

                    {hasSession && <Stack.Screen
                        name="ChatKyra"
                        component={ChatKyra} />}

                    {hasSession && <Stack.Screen
                        name="ChatTherapist"
                        component={ChatTherapist} />}
                </Stack.Navigator>
            </NavigationContainer> :
            <LoadingScreen />
        }
    </>);
}

export default App;
