import { useContext } from "react";
import { io } from "socket.io-client";
import { BASE_API, useHttp } from "../hooks/http";
import { SessionContext } from "../provider/SessionProvider";
import { IPaginatedResponse, PaginatedResponse } from "../models/ApiResponse";
import { TherapistChatContext } from "../provider/TherapistChatProvider";

export interface ChatMessage {
    therapist_id: number;
    user_id: number;
    message: string;
    from_therapist: boolean;
    send_at: Date;

    //This property is not in the API,
    // is used to show a flag of sent in the chat
    _ok: boolean;
}

export const useChat = () => {

    const http = useHttp(BASE_API, true);

    const chatContext = useContext(TherapistChatContext);
    const session = useContext(SessionContext);

    const listHistory = async (pageNum: number, search?: string): Promise<PaginatedResponse<ChatMessage>> => {
        const response = await http.get<IPaginatedResponse<ChatMessage>>(`/userapi/chat/history`, {
            page_num: pageNum.toString(),
            page_size: "30",
            search
        })
            .then((response) => response?.data || { items: [], total: 0 })
            .catch((error) => {
                //TODO: Handle error
                console.error('Error:', error);
                return { items: [], total: 0 };
            });

        response.items.forEach((message) => {
            message.send_at = new Date(message.send_at);
            message._ok = true;
        });

        return new PaginatedResponse(response, pageNum, 20);
    };

    const connectChatSocket = async (): Promise<void> => {
        if (chatContext.socket?.value && chatContext.socket?.value?.connected) {
            return;
        }

        const socket = io(BASE_API + `/userapi/chat`, {
            auth: {
                token: session?.session?.current?.accessToken
            }
        });

        return await new Promise((resolve, reject) => {
            socket.on('connect', () => {
                chatContext.socket?.set(socket);
                resolve();
            });

            socket.on('error', () => {
                reject();
            });

            socket.on('message', (message: ChatMessage) => {
                console.log('Message received in socket:', message);
                message.send_at = new Date(message.send_at);
                chatContext.eventListener?.value?.dispatchEvent(new CustomEvent('message', { detail: message }));
            });
        });
    };

    const sendMessage = async (message: string): Promise<ChatMessage> => {
        if (!chatContext.socket?.value) {
            throw new Error('Chat socket is not connected');
        }

        return new Promise((resolve) =>
            chatContext.socket?.value?.emit('message', { message }, (msg: ChatMessage) => {
                msg.send_at = new Date(msg.send_at);
                msg._ok = true;
                resolve(msg)
            })
        );
    };

    return {
        listHistory,
        connectChatSocket,
        sendMessage
    };
};
