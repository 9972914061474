import { useContext, useMemo } from "react";
import { NavigationWithProps } from "../../../core/Types";
import { PagesNavParamList } from "../Pages";
import { Text, useTheme } from "react-native-paper";
import { Image, ImageBackground, SafeAreaView, StatusBar, View } from "react-native";
import { AppStoreContext } from "../../../core/provider/AppStoreProvider";
import B from "../../../components/Bold";
import { getProfileImageUrl } from "../../../utils/Procedures";
import { usePublicApi } from "../../../core/api/publicApi";
import { Background, Pet, Scenes } from "../../../assets/images";
import { useDialog } from "../../../core/hooks/dialog";

const Chat: React.FC<NavigationWithProps<{}, PagesNavParamList, "Chat", "AppNavigator">> = ({ navigation, route }) => {
    const theme = useTheme();

    const publicApi = usePublicApi();
    const dialog = useDialog();

    const appContext = useContext(AppStoreContext);

    const therapist = useMemo(() => {
        return appContext.myTherapist?.value?.therapist;
    }, [appContext.myTherapist]);

    const chatWithTherapist = () => {
        navigation.getParent()?.navigate("ChatTherapist");
    };

    const chatWithKyra = () => {
        if (appContext.myTherapist?.value?.therapist?.has_kyra === true) {
            navigation.getParent()?.navigate("ChatKyra");
        } else {
            dialog.openDialog("Kyra no habilitada", "Tu terapeuta no tiene habilitada la terapia con Kyra.");
        }
    };

    return (<>
        <SafeAreaView style={{ flex: 1 }}>
            <StatusBar
                barStyle={theme.dark ? "light-content" : "dark-content"}
                backgroundColor="transparent"
                translucent={true}
            />
            <ImageBackground
                style={{ flex: 1, paddingHorizontal: 10 }}
                source={Background.sky_fullscreen}>

                <Text style={{
                    fontSize: 28,
                    textAlign: "center",
                    marginTop: 60,
                    marginBottom: 70
                }}>
                    Habla con Kyra o tu terapeuta
                </Text>

                <View style={{
                    borderRadius: 30,
                    flexDirection: 'row',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    padding: 15,
                    backgroundColor: theme.colors.surfaceVariant,
                    marginTop: 50,
                    marginBottom: 20
                }}
                    onPointerUp={chatWithTherapist}>
                    {therapist &&
                        <Image
                            style={{
                                height: 70,
                                width: 70,
                                borderRadius: 50,
                                marginRight: 10
                            }}
                            source={getProfileImageUrl(therapist, publicApi)} />}
                    <View style={{
                        flexBasis: "80%"
                    }}>
                        <Text style={{ fontSize: 20, color: theme.colors.onSurfaceVariant }}><B>{therapist?.full_name}</B></Text>
                        {/* <Text style={{ fontSize: 14, color: theme.colors.onSurfaceVariant }}></Text> */}
                    </View>
                </View>

                {/* <View style={{
                    borderRadius: 30,
                    flexDirection: 'row',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    padding: 15,
                    backgroundColor: theme.colors.surfaceVariant,
                }}
                    onPointerUp={chatWithKyra}>

                    <Image
                        style={{
                            height: 70,
                            width: 70,
                            borderRadius: 50,
                            marginRight: 10
                        }}
                        source={Pet.face} />
                    <View style={{
                        flexBasis: "80%"
                    }}>
                        <Text style={{ fontSize: 20, color: theme.colors.onSurfaceVariant }}><B>Kyra</B></Text>
                        <Text style={{ fontSize: 14, color: theme.colors.onSurfaceVariant }}>
                            Hola {appContext.myProfile?.value?.shortName} 😊 ¿Cómo te sientes hoy?
                        </Text>
                    </View>
                </View> */}
            </ImageBackground>
        </SafeAreaView>
    </>);
};

export default Chat;
