import { BASE_API, useHttp } from "../hooks/http";

export enum ChatMessageUserType {
    KYRA = 1,
    THERAPIST = KYRA,
    USER = 2
}

export interface IChatMessage {
    type: ChatMessageUserType;
    text: string;
}

export const useChatbot = () => {
    const http = useHttp(BASE_API, true);

    const autocomplete = async (messages: IChatMessage[]): Promise<string> => {
        return await http.post<string>('/userapi/chatbot/', { messages })
            .then((response) => {
                if (!response?.ok || !response?.data) {
                    throw new Error('Error sending message');
                }

                return response.data;
            })
    }

    return {
        autocomplete
    };
};
