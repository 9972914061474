import { ReactNode, createContext, useState } from "react";
import { Socket } from "socket.io-client";

type TherapistChatContextProps = {
    socket?: {
        value: Socket | undefined;
        set: React.Dispatch<React.SetStateAction<Socket | undefined>>;
    },
    eventListener?: {
        value: EventTarget;
        set: React.Dispatch<React.SetStateAction<EventTarget>>;
    },
}

export const TherapistChatContext = createContext<TherapistChatContextProps>({});

export const TherapistChatProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    // TODO: Maybe we can use useRef here?
    const [chatSocket, setChatSocket] = useState<Socket | undefined>(undefined);
    const [eventListener, setEventListener] = useState(new EventTarget());

    const obj = {
        socket: {
            value: chatSocket,
            set: setChatSocket
        },
        eventListener: {
            value: eventListener,
            set: setEventListener
        }
    };

    return (
        <TherapistChatContext.Provider value={obj}>
            {children}
        </TherapistChatContext.Provider>
    );
}
