import { ApiResponse } from '../models/ApiResponse';
import { useAuth } from '../api/auth';
import { useContext, useMemo, useState } from 'react';
import { Session, SessionContext } from '../provider/SessionProvider';
import AsyncLock from '../../utils/asyncLock';

type HttpParams = { [key: string]: string | undefined };

// export const BASE_API = "https://192.168.1.18:8080/api/v1";
export const BASE_API = "https://psicopro-api-ftdnvi23yq-no.a.run.app/api/v1";

export const useHttp = (baseUrl: string, authenticated: boolean) => {
    const auth = useAuth();

    const session = useContext(SessionContext);

    const __refreshLock = useState<AsyncLock>(new AsyncLock())[0];

    const __addAuthenticationHeader = (headers: any) => {
        if (authenticated) {
            headers['Authorization'] = `Bearer ${session?.session?.current?.accessToken}`;
        }
    };

    const __buildHeader = (isJSON = true) => {
        const headers: HeadersInit = {};

        if (isJSON) {
            headers['Content-Type'] = 'application/json';
        }

        __addAuthenticationHeader(headers);

        return headers;
    };

    const _withParams = (path: string, { params, baseUrl }: { params?: HttpParams, baseUrl?: string }): string => {
        const url = new URL(baseUrl + path);
        if (params) {
            Object.keys(params)
                .filter(key => params[key] !== undefined)
                .forEach(key => url.searchParams.append(key, params[key]!));
        }

        return url.toString();
    };

    const _refreshToken = async (): Promise<void> => {
        await auth.refreshToken()
            .catch((e) => {
                console.error('...Error refreshing token');
                auth.logout();
            });
    };

    const _fetcher = async <T>(url: string, init: RequestInit): Promise<ApiResponse<T> | undefined> => {
        const responseBody = (response: Response) => {
            if (!response.ok) {
                throw response;
            }

            return response.json()
        };

        let retry = 0;
        let success: boolean;
        let result: ApiResponse<T> | undefined;
        do {
            success = true;
            result = await fetch(url, init)
                .then(responseBody)
                .catch(async (error) => {
                    if (!authenticated || error.status !== 401) {
                        throw error;
                    }

                    //Only authenticated requests can be retried

                    if (retry == 1) {
                        // No more retries
                        throw error;
                    }

                    success = false;
                    retry += 1;

                    if (__refreshLock.isLocked()) {
                        await __refreshLock.lock();
                    } else {
                        await __refreshLock.lock();
                        await _refreshToken();
                    }
                    __refreshLock.unlock();
                    __addAuthenticationHeader(init.headers);
                });
        } while (!success);

        return result;
    };

    const get = async <T>(path: string, params?: HttpParams): Promise<ApiResponse<T> | undefined> => {
        return _fetcher(_withParams(path, { params, baseUrl }), {
            method: "GET",
            headers: __buildHeader(),
        });
    };

    const post = async <T>(path: string, data?: FormData | any): Promise<ApiResponse<T> | undefined> => {
        const formData = data instanceof FormData;
        return _fetcher(_withParams(path, { baseUrl }), {
            method: "POST",
            headers: __buildHeader(!formData),
            body: formData ? data : JSON.stringify(data)
        });
    };

    const put = async <T>(path: string, data?: FormData | any): Promise<ApiResponse<T> | undefined> => {
        const formData = data instanceof FormData;
        return _fetcher(_withParams(path, { baseUrl }), {
            method: "PUT",
            headers: __buildHeader(!formData),
            body: formData ? data : JSON.stringify(data)
        });
    };

    //delete is not allowed as a valid variable name
    const dellete = async <T>(path: string, params?: HttpParams): Promise<ApiResponse<T> | undefined> => {
        return _fetcher(_withParams(path, { params, baseUrl }), {
            method: "DELETE",
            headers: __buildHeader(),
        });
    };

    return {
        get baseUrl() { return baseUrl; },
        get,
        post,
        put,
        delete: dellete
    };
};
